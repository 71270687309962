.theme-default {
    --light-bulb-circle-color: rgba(229, 210, 123, 1);
    --light-bulb-circle-shadow: 0px 0px 11px 0px rgba(229, 210, 123, 1);
    --border-color: rgba(229, 210, 123, 1);
    --success-section-bg: rgba(230, 28, 69, 1);
    --success-section-title-color: #fff;
    --success-section-desc-color: #fff;
}

.theme-christmas {
    --light-bulb-circle-color: #4BA59D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #4BA59D;
    --border-color: #4BA59D;
    --success-section-bg: rgba(230, 28, 69, 1);
    --success-section-top-desc-color: #212529;
    --success-section-title-color: #fff;
    --success-section-desc-color: #fff;
}

.theme-newyear {
    --light-bulb-circle-color: #F34C4F;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #F34C4F;
    --border-color: #F34C4F;
    --success-section-bg: #FFE3AF;
    --success-section-top-desc-color: #212529;
    --success-section-title-color: #E61C45;
    --success-section-desc-color: #212529; 
}

.theme-love {
    --light-bulb-circle-color: #FEC04D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #FEC04D;
    --border-color: #ED0C79;
    --success-section-bg: #FFF4F6;
    --success-section-top-desc-color: #212529;
    --success-section-title-color: #E61C45;
    --success-section-desc-color: #E61C45; 
}

.theme-spring {
    --light-bulb-circle-color: #FEC04D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #FEC04D;
    --border-color: #283713;
    --inner-border-color: #E9BB56;
    --success-section-bg: #FFF4F6;
    --success-section-top-desc-color: #212529;
    --success-section-title-color: #212529;
    --success-section-desc-color: #212529; 
}

.success-section{
    overflow-x: hidden;
    .lights{
        display: flex;
        column-gap: 32px;
        margin-top: 16px;
        height: 24px;
        align-items: center;

        .circle{
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        .circle:nth-child(odd){
            box-shadow: 0px 0px 11px 0px var(--light-bulb-circle-color);
            -webkit-box-shadow: 0px 0px 11px 0px var(--light-bulb-circle-color);
            -moz-box-shadow: 0px 0px 11px 0px var(--light-bulb-circle-color);
            background-color: var(--light-bulb-circle-color);
        }

        .circle:nth-child(even){
            box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            -webkit-box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            -moz-box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            background-color: rgba(255, 255, 255, 1);
        }
    }

    .success-notification{
        background-color: rgba(33, 37, 41, 1);
        border-radius: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        .success-content {
            border-radius: 32px;
            border: 8px solid var(--border-color);
            box-shadow: inset 0 0 0 8px var(--inner-border-color);

            background: var(--success-section-bg); 
            width: 335px;
            min-height: 159px;
            padding: 16px;
        
            .success-content-top-desc{
                color: var(--success-section-top-desc-color) !important;
            }

            .success-content-title{
                color: var(--success-section-title-color) !important;
            }

            .success-content-desc{
                color: var(--success-section-desc-color) !important;
            }
        }

        .badge{
            position: absolute;
            width: 24px;
            height: 24px;
            background-color: #45484B;
            border-radius: 50%;
            left: 24px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}