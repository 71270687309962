.theme-default {
    --gradient-1: linear-gradient(to left, #F8DFA0, #F6D671, #E9CB6C);
    --gradient-2: linear-gradient(to left, #F5C086, #F2A967, #F7D879);
    --gradient-3: linear-gradient(to left, #F7CE96, #F3AC73, #F2A867, #F4CB6E, #E9CB6C);
}

.theme-christmas {
    --gradient-1: linear-gradient(to left, rgb(244, 175, 175), #FE5F55, #FE5F55);
    --gradient-2: linear-gradient(to left, rgb(244, 175, 175), #FE5F55, #FE5F55);
    --gradient-3: linear-gradient(to left, rgb(244, 175, 175), #FE5F55, #FE5F55);
}

.theme-newyear {
    --gradient-1: linear-gradient(to left, #F1D7A8, #faaea9, #faaea9, #F1D7A8, #F1D7A8);
    --gradient-2: linear-gradient(to left, #F1D7A8, #F1D7A8, #F1D7A8, #F1D7A8, #F1D7A8);
    --gradient-3: linear-gradient(to left, #F1D7A8, #F1D7A8, #faaea9, #F1D7A8, #F1D7A8);
}

.theme-love {
    --gradient-1: linear-gradient(to left, #F37DB4, #F37DB4, #ED2279, #ED2279, #ED2279);
    --gradient-2: linear-gradient(to left, #F37DB4, #F37DB4, #ED2279, #ED2279, #ED2279);
    --gradient-3: linear-gradient(to left, #F37DB4, #F37DB4, #ED2279, #ED2279, #ED2279);
}

.theme-spring {
    --gradient-1: linear-gradient(to left,#e9bb56,#f8dd85, #F6D671, #F6D671, #DEC77E);
    --gradient-2:linear-gradient(to left,#fce28c,#f8e49f, #e9bb56, #e9bb56, #DEC77E);
    --gradient-3: linear-gradient(to left,#fce28c,#e9bb56, #e9bb56, #e9bb56, #DEC77E);
}

.option-borders {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    pointer-events: none;
    z-index: 15;

    .line {
        position: absolute;
        width: 100%;
        transform-origin: center center;
        top: 49%;
        left: 26%;
        width: 145px;
        border: 4px solid transparent;
        background-origin: border-box;
        background-clip: content-box, border-box;
    }

    &.options-3 {
        .line:nth-child(1) {
            transform: rotate(31deg) translateX(-50%);
            width: 155px;
            background-image: linear-gradient(white, white), var(--gradient-1);
        }

        .line:nth-child(2) {
            transform: rotate(151deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-2);
        }

        .line:nth-child(3) {
            transform: rotate(270deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-3);
        }
    }

    &.options-4 {
        .line:nth-child(1) {
            transform: rotate(44deg) translateX(-50%);
            width: 155px;
            background-image: linear-gradient(white, white), var(--gradient-1);
        }

        .line:nth-child(2) {
            transform: rotate(134deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-2);
        }

        .line:nth-child(3) {
            transform: rotate(224deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-3);
        }

        .line:nth-child(4) {
            transform: rotate(313deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-1);
        }
    }

    &.options-5 {
        .line:nth-child(1) {
            transform: rotate(52deg) translateX(-50%);
            width: 155px;
            background-image: linear-gradient(white, white), var(--gradient-1);
        }

        .line:nth-child(2) {
            transform: rotate(124deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-1);
        }

        .line:nth-child(3) {
            transform: rotate(198deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-2);
        }

        .line:nth-child(4) {
            transform: rotate(269deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-3);
        }

        .line:nth-child(5) {
            transform: rotate(341deg) translateX(-50%);
            background-image: linear-gradient(white, white), var(--gradient-1);
        }
    }
}