.footerLabel {
    color: #abb0b6 !important;
    font-size: 14px !important;
  }

@media screen and (min-width: 768px) {
    .footerLabel {
        color: #fff !important;
        font-size: 16px !important;
      }
}