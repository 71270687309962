.form-section {
    label {
        display: block;
        font-size: 14px;
        color: #fff;
        line-height: 20px;
    }
    
    input {
        width: 100%;
        height: 48px;
        border-radius: 4px;
        padding: 0px 4px; 
        outline: none;
        border: none;
        box-sizing: border-box; 
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; 
    }

    input[type="number"] {
        -moz-appearance: textfield;
        padding-left: 32px;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }

    input[type="checkbox"] {
        width: 20px;
        height: 20px;
        border-radius: 16px;
    }

    .prefix {
        position: absolute;
        top: 50%; 
        left: 5px; 
        transform: translateY(-50%);
        font-size: 15px;
        pointer-events: none;
        color: #212529;
    }

    .position-relative {
        position: relative;
    }
}
