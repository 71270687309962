.modal-container {
    overflow: hidden;
    max-width: 450px;
    width: 100%; 

    .modal-footer{
        max-width: 343px;
        width: 100%;
        margin: 0 auto;
    }
}

.ice-overlay-top{
    height: 200px;
}

.spring-leaves-top{
    height: 139px;
    z-index: -1;
}

.ice-overlay-bottom{
    height: 200px;
}

.ice-overlay-bottom{
    height: 150px;
}

@media(max-width: 992px){
    .ice-overlay-top, .ice-overlay-bottom{
        height: 100px;
    }
}

@media(min-width: 1200px){
    .modal-container {
        max-width: 480px;
        width: 440px;
    }
}