// Themes
.theme-default {
    --light-bulb-circle-color: rgba(229, 210, 123, 1);
    --light-bulb-circle-shadow: 0px 0px 11px 0px rgba(229, 210, 123, 1);
    --light-sharpness: radial-gradient(circle, rgba(241, 241, 241, 0.23) 20%, rgba(255, 255, 255, 0) 70%);
    --wheel-outer-border: rgba(229, 210, 123, 1);
    --wheel-border: rgb(33, 37, 41);
    --wheel-inner-border: rgba(229, 210, 123, 1);
    --wheel-segment-color-even: #FFF5DC;
    --wheel-segment-color-odd: #EFDFB5;
    --wheel-segment-color-last-segment: #f7ebd0;
    --wheel-option-title-color:rgba(230, 28, 69, 1);
    --wheel-option-desc-color:rgba(33, 37, 41, 1);
    --wheel-option-expiration-text-color:rgba(33, 37, 41, 0.6);
    --wheel-button-background-color: rgba(33, 37, 41, 1);
}

.theme-christmas {
    --light-bulb-circle-color: #4BA59D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #4BA59D;
    --light-sharpness: radial-gradient(circle, rgba(241, 241, 241, 0.23) 20%, rgba(255, 255, 255, 0) 70%);
    --wheel-outer-border: #FE5F55;
    --wheel-border: #4BA59D;
    --wheel-inner-border: #FE5F55;
    --wheel-segment-color-even: #FFF5DC;
    --wheel-segment-color-odd: #EFDFB5;
    --wheel-segment-color-last-segment: #f7ebd0;
    --wheel-option-title-color:rgba(230, 28, 69, 1);
    --wheel-option-desc-color:rgba(33, 37, 41, 1);
    --wheel-option-expiration-text-color:rgba(33, 37, 41, 0.6);
    --wheel-button-background-color: rgba(33, 37, 41, 1);
}

.theme-newyear {
    --light-bulb-circle-color: #F34C4F;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #F34C4F;
    --light-sharpness: radial-gradient(circle, rgba(241, 241, 241, 0.23) 20%, rgba(255, 255, 255, 0) 70%);
    --wheel-outer-border: #FFFFFF;
    --wheel-border: #F34C4F;
    --wheel-inner-border: #F1D7A8;
    --wheel-segment-color-even: radial-gradient(circle, #fc4a4d 30%, #FF9F9B 100%);
    --wheel-segment-color-odd: linear-gradient(to bottom, #e5595b 0%, #f68682 100%);
    --wheel-segment-color-last-segment: linear-gradient(to bottom right, #ec3033 0%, #F5958D 100%);
    --wheel-option-title-color:#fff;
    --wheel-option-desc-color:#fff;
    --wheel-option-title-color:#fff;
    --wheel-option-desc-color:#fff;
    --wheel-option-expiration-text-color:#fff;
    --wheel-button-background-color: rgba(33, 37, 41, 1);
}

.theme-love {
    --light-bulb-circle-color: #FEC04D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #FEC04D;
    --light-sharpness: radial-gradient(circle, rgba(241, 241, 241, 0.783) 20%, rgba(255, 255, 255, 0) 90%);
    --wheel-outer-border: #FFFFFF;
    --wheel-border: #FFF8F9;
    --wheel-inner-border: #ED2279;
    --wheel-segment-color-even: #FFF4F6;
    --wheel-segment-color-odd: #FFF4F6;
    --wheel-segment-color-last-segment: #FFF4F6;
    --wheel-option-title-color:#E61C45;
    --wheel-option-desc-color:#E61C45;
    --wheel-option-title-color:#E61C45;
    --wheel-option-desc-color:#E61C45;
    --wheel-option-expiration-text-color:#E61C45;
    --wheel-container-box-shadow: inset 0px 0px 5px 25px rgba(237,12,121,0.6), 
    0px 0px 20px 7px rgba(237,12,121,1); 
    --wheel-button-background-color: rgba(33, 37, 41, 1);
}

.theme-spring {
    --light-bulb-circle-color: #FEC04D;
    --light-bulb-circle-shadow: 0px 0px 11px 0px #FEC04D;
    --light-sharpness: radial-gradient(circle, rgba(241, 241, 241, 0.23) 20%, rgba(255, 255, 255, 0) 70%);
    --wheel-outer-border: #FFFFFF;
    --wheel-border: #283713;
    --wheel-inner-border: #DEC77E;
    --wheel-segment-color-even: #E8E1CF;
    --wheel-segment-color-odd: #E8E1CF;
    --wheel-segment-color-last-segment: #E8E1CF;
    --wheel-option-title-color:#212529;
    --wheel-option-desc-color:#212529;
    --wheel-option-title-color:#212529;
    --wheel-option-desc-color:#212529;
    --wheel-option-expiration-text-color:#212529;
    --wheel-container-box-shadow: none; 
    --wheel-button-background-color: #9DD900;
}

.wheel-container {
    position: relative;
    width: 344px;
    height: 344px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 4px solid var(--wheel-outer-border);
    padding: 10px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: var(--wheel-container-box-shadow);
    
    &::before
    {
        content: "";
        position: absolute;
        inset: -4px; 
        border-radius: 50%;
        padding: 4px; 
        background: linear-gradient(to bottom, #E5D27B, #E8E1CF, #E8E1CF, #E8E1CF, #E5D27B, #E5D27B, #E8E1CF, #E8E1CF, #E8E1CF, #E5D27B);
        -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
    
    .svg-border-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        width: calc(100% + 10px); 
        height: calc(100% + 10px); 
        transform: translate(-50%, -50%); 
        pointer-events: none; 
        z-index: 2;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }

    &::after{
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 303px;
        height: 303px;
        border-radius: 50%;
        border: 8px solid var(--wheel-inner-border); 
    }

    .border-circles {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        pointer-events: none; 

        .circle {
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            z-index: 5;
        }

        .circle:nth-child(1) { top: -8px; left: 50%; transform: translateX(-50%); }     
        .circle:nth-child(2) { top: 14%; left: 85.5%; transform: translate(-50%, -50%); } 
        .circle:nth-child(3) { top: 50%; right: -8px; transform: translateY(-50%); }    
        .circle:nth-child(4) { bottom: 14%; right: 11%; transform: translate(-50%, 50%); }  
        .circle:nth-child(5) { bottom: -8px; left: 50%; transform: translateX(-50%); }    
        .circle:nth-child(6) { bottom: 14%; left: 14.5%; transform: translate(-50%, 50%); }  
        .circle:nth-child(7) { top: 50%; left: -8px; transform: translateY(-50%); }    
        .circle:nth-child(8) { top: 14%; left: 14.5%; transform: translate(-50%, -50%); }  

        .circle:nth-child(odd){
            box-shadow: var(--light-bulb-circle-shadow);
            background-color: var(--light-bulb-circle-color);
        }

        .circle:nth-child(even){
            box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            -webkit-box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            -moz-box-shadow: 0px 0px 11px 0px rgba(255,255,255,1);
            background-color: rgba(255, 255, 255, 1);
        }
    }


    .lights-wrapper{
        .light {
            position: absolute;
            width: 75px;
            height: 40px;
            border-radius: 50%;
            z-index: 5;
            background: var(--light-sharpness) !important;
        }

        .light:nth-child(1) { top: 28%; left: 65.5%; transform: translate(-50%, -50%); 
            background: rgba(254, 254, 254, 0.138);
            top: 26%;
            left: 72.5%;
            transform: translate(-50%, -50%);
            rotate: 40deg;
        }     
        .light:nth-child(2) {  
            bottom: 26%;
            right: 7%;
            transform: translate(-50%, 50%);
            background: rgba(254, 254, 254, 0.138);
            rotate: -40deg;
        } 
        .light:nth-child(3) {bottom: 11%;
            bottom: 12%;
            left: 23.5%;
            transform: translate(-50%, 50%); 
            background: rgba(254, 254, 254, 0.127);
            rotate: 40deg;
        }    
        .light:nth-child(4) {   
            top: 11%;
            left: 23.5%;
            transform: translate(-50%, -50%);
            background: rgba(254, 254, 254, 0.127);
            rotate: -40deg;

        }  
    }
    
    .ui-wheel-of-fortune {
        all: unset;
        aspect-ratio: 1 / 1;
        display: grid;
        position: relative;
        width: 100vw;
        border: 8px solid var(--wheel-border); 
        border-radius: 50%;
        transition: transform 6.1s ease-out;
     
        & > * {
            position: absolute;
        }

        ul {
            all: unset;
            clip-path: inset(0 0 0 0 round 50%);
            -webkit-clip-path: inset(0 0 0 0 round 50%);
            display: grid;
            inset: 0;
            place-content: center start;

            li {
                align-content: center;
                aspect-ratio: 1 / calc(2 * tan(180deg / var(--_items)));
                clip-path: polygon(-2% -2%, 102% 50%, -2% 104%);
                -webkit-clip-path: polygon(-2% -2%, 102% 50%, -2% 104%);

                display: grid;
                grid-area: 1 / -1;
                padding-left: 1.8rem;
                rotate: calc(360deg / var(--_items) * (var(--_idx) - 1) + 90deg);
                transform-origin: center right;
                user-select: none;
                width: 151px;
                position: relative;

                &:nth-of-type(1) { --_idx: 1; }
                &:nth-of-type(2) { --_idx: 2; }
                &:nth-of-type(3) { --_idx: 3; }
                &:nth-of-type(4) { --_idx: 4; }
                &:nth-of-type(5) { --_idx: 5; }
                &:nth-of-type(6) { --_idx: 6; }

                &:nth-child(even) {
                    background: var(--wheel-segment-color-even) !important;
                }
                
                &:nth-child(odd) { 
                    background: var(--wheel-segment-color-odd); 
                }

                .text-section{
                    rotate: -90deg !important; 
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transform: translateY(-35px);
                }

                .wheel-option-title {
                    text-align: center;
                    color: var(--wheel-option-title-color);
                    line-height: 32px;
                    margin-bottom: 0px;
                }
                .wheel-option-desc {
                    color: rgba(33, 37, 41, 1);
                    color: var(--wheel-option-desc-color);
                    margin-bottom: 0px;
                }
                .option-gone-text {
                    color: var(--wheel-option-expiration-text-color);
                    margin-bottom: 0px;
                }
            }

            &[data-items="3"] li {
                aspect-ratio: 1 / 2; 
                clip-path: polygon(0% -40%, 100% 50%, 0% 136%);
                rotate: calc(120deg * (var(--_idx) - 1) + 90deg);
            }
    
            &[data-items="2"] li {
                aspect-ratio: 1 / 2; 
                clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
                rotate: calc(180deg * (var(--_idx) - 1) + 90deg);
            }
        }

        .last-odd {
            background: var( --wheel-segment-color-last-segment) !important;
        }

        

        .selected-option {
            background: #FEC04D !important;
            color: white;
        }

        .expired {
            position: relative; 
            opacity: 1; 
            pointer-events: none;

            &::after {
                content: "";
                position: absolute;
                background-color: #EBEBEB;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 10;
                opacity: 0.7;
                color: rgba(33, 37, 41, 0.4);
            }
        }
    }

    .button-container {
        position: absolute;
        top: 48.2%;
        left: 50.2%;
        transform: translate(-50%, -50%);
        z-index: 30;

        button {
            background: var(--wheel-button-background-color);
            border: 1px solid #fff;
            border-radius: 50%;
            font-size: 1rem;
            padding: 10px; 
            color: #fff;
            font-weight: bold;
            box-shadow: 0px 1.25px 0.25px 0px rgb(255, 255, 255, 0.4);
            -webkit-box-shadow: 0px 1.25px 0.25px 0px rgb(255, 255, 255, 0.4);
            -moz-box-shadow: 0px 1.25px 0.25px 0px rgb(255, 255, 255, 0.4);
        }
    }

    .arrow{
        position: absolute;
        left: 50%;
        top: 6px;
        transform: translateX(-50%);
        z-index: 10;
    }
}

@media(min-width: 1200px){
    .wheel-container {
        scale: 1.08;
        margin: 48px auto;
    }
}

@media(max-width: 768px){
    .wheel-container {
        scale: 0.94;
    }
}


@media(max-width: 380px){
    .wheel-container {
        position: relative;
        right: 1%;
        scale: 0.88;
    }
}
